import React from "react";
import Layout from "@/components/Layout";

export default function SearchPage() {
  return (
    <>
      <Layout>
        <div className="my-10">
          <script src="https://cse.google.com/cse.js?cx=fd73c201cbd659445"></script>
          <div className="gcse-search"></div>
        </div>
      </Layout>
    </>
  );
};
